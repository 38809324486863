import React, { useState, ChangeEvent, FormEvent } from "react";
import { Container, TextField, Button, Typography } from "@mui/material";
import axios from "axios";
import { Contact } from "../interfaces";

const ContactPage: React.FC = () => {
  const [contact, setContact] = useState<Contact>({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setContact({ ...contact, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    axios
      .post("/api/contact", contact)
      .then((response) => {
        alert("Message sent successfully!");
      })
      .catch((error) => {
        alert("Error sending message");
      });
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Contact Us
      </Typography>
      {/* <Typography variant="body1" gutterBottom>
        Email: 
        </Typography> */}

      {/* <form onSubmit={handleSubmit}>
        <TextField
          name="name"
          label="Name"
          fullWidth
          margin="normal"
          onChange={handleChange}
        />
        <TextField
          name="email"
          label="Email"
          type="email"
          fullWidth
          margin="normal"
          onChange={handleChange}
        />
        <TextField
          name="message"
          label="Message"
          multiline
          rows={4}
          fullWidth
          margin="normal"
          onChange={handleChange}
        />
        <Button type="submit" variant="contained" color="primary">
          Send
        </Button>
      </form> */}
    </Container>
  );
};

export default ContactPage;
