import { useState, useEffect } from "react";
import { getFirestore, collection, onSnapshot } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { db } from "../../utils";
import { set } from "date-fns";

export interface Choice {
  name: string;
  price: string;
}
export interface MenuItem {
  name: string;
  description: string;
  price?: string;
  choices?: Choice[];
}

export interface MenuData {
  appetizers: MenuItem[];
  entrees: MenuItem[];
  "curry Dishes": MenuItem[];
  "side Dishes": MenuItem[];
  "specialty Dishes": MenuItem[];
}

const menuData: MenuData = {
  appetizers: [
    {
      name: "Fried Plantains",
      description: "Lightly fried sweet plantains, golden and crispy",
      price: "$5.99",
    },
    {
      name: "Suya (Spicy Beef Skewers)",
      description: "Marinated, grilled beef skewers with West African spices",
      price: "$14.99",
    },
    {
      name: "Moi Moi",
      description: "Savory steamed bean pudding made with peppers and onions",
      price: "$3.99",
    },
    {
      name: "Puff Puff",
      description: "Sweet, fluffy dough balls fried to perfection",
      price: "$3.99",
    },
    {
      name: "Meat Pie",
      description:
        "Flaky pastry filled with seasoned ground beef, potatoes, and vegetables",
      price: "$2.99",
    },
    {
      name: "Vegetable Spring Rolls",
      description: "Crispy spring rolls filled with seasoned vegetables",
      price: "$4.99",
    },
    {
      name: "Small Chops Platter",
      description:
        "A sampler of puff puff, spring rolls, samosas, and stewed beef",
      price: "$15.99",
    },
    {
      name: "Jamaican Beef Patty",
      description:
        "Flaky golden pastry filled with seasoned ground beef and spices",
      price: "$4.99",
    },
  ],
  entrees: [
    {
      name: "Jollof Rice",
      description:
        "Served with your choice of protein and accompanied by fried plantains",
      price: "Starting at $14.99",
      choices: [
        {
          name: "Chicken or Turkey",
          price: "$14.99",
        },
        {
          name: "Beef",
          price: "$16.99",
        },
        {
          name: "Goat Meat",
          price: "$17.99",
        },
        {
          name: "Whole Tilapia Fish",
          price: "$19.99",
        },
        {
          name: "Pompano Fish or Snapper Fish",
          price: "$20.99",
        },
      ],
    },
    {
      name: "Fried Rice with Beef and Shrimps",
      description:
        "Served with your choice of protein and accompanied by fried plantains",
      price: "Starting at $15.99",
      choices: [
        {
          name: "Chicken or Turkey",
          price: "$15.99",
        },
        {
          name: "Beef",
          price: "$17.99",
        },
        {
          name: "Goat Meat",
          price: "$18.99",
        },
        {
          name: "Whole Tilapia Fish",
          price: "$19.99",
        },
        {
          name: "Pompano Fish or Snapper Fish",
          price: "$20.99",
        },
      ],
    },
    {
      name: "White Rice",
      description:
        "Served with your choice of protein and accompanied by fried plantains",
      price: "Starting at $14.99",
      choices: [
        {
          name: "Chicken or Turkey",
          price: "$14.99",
        },
        {
          name: "Beef",
          price: "$16.99",
        },
        {
          name: "Goat Meat",
          price: "$18.99",
        },
        {
          name: "Whole Tilapia Fish",
          price: "$19.99",
        },
        {
          name: "Pompano Fish or Snapper Fish",
          price: "$20.99",
        },
      ],
    },
    {
      name: "Egusi Soup",
      description:
        "Traditional melon seed soup served with one wrap of pounded yam",
      price: "Starting at $16.99",
      choices: [
        {
          name: "Assorted Meat",
          price: "$16.99",
        },
        {
          name: "Beef",
          price: "$16.99",
        },
      ],
    },
    {
      name: "Vegetable Soup",
      description: "Hearty vegetable soup served with one wrap of pounded yam",
      price: "Starting at $16.99",
      choices: [
        {
          name: "Assorted Meat",
          price: "$16.99",
        },
        {
          name: "Beef",
          price: "$16.99",
        },
      ],
    },
    {
      name: "Okra Soup",
      description: "Flavorful okra soup served with one wrap of pounded yam",
      price: "Starting at $16.99",
      choices: [
        {
          name: "Assorted Meat",
          price: "$16.99",
        },
        {
          name: "Beef",
          price: "$16.99",
        },
      ],
    },
    {
      name: "Ogbono Soup",
      description: "Nutty, savory soup served with one wrap of pounded yam",
      price: "Starting at $16.99",
      choices: [
        {
          name: "Assorted Meat",
          price: "$16.99",
        },
        {
          name: "Beef",
          price: "$16.99",
        },
      ],
    },
    {
      name: "Pepper Soup",
      description: "Spicy, aromatic soup",
      price: "Starting at $17.99",
      choices: [
        {
          name: "With Goat Meat",
          price: "$17.99",
        },
        {
          name: "With Pompano Fish",
          price: "$19.99",
        },
      ],
    },
    {
      name: "Grilled Tilapia",
      description:
        "Whole tilapia served with roasted plantain or fries, and a salad",
      price: "$18.99",
    },
    {
      name: "Grilled Pompano Fish",
      description: "Served with roasted plantain and a side salad",
      price: "$21.99",
    },
  ],
  "curry Dishes": [
    {
      name: "Curry Chicken",
      description:
        "Tender chicken simmered in a flavorful curry sauce, served with white rice",
      price: "$12.99",
    },
    {
      name: "Curry Goat",
      description:
        "Slow-cooked goat in rich curry sauce, served with white rice",
      price: "$17.99",
    },
  ],
  "side Dishes": [
    {
      name: "Fried Plantains",
      description: "Sweet plantains sliced and lightly fried until golden",
      price: "$5.99",
    },
    {
      name: "Moi Moi",
      description: "Savory steamed bean pudding made with peppers and onions",
      price: "$3.99",
    },
    {
      name: "Jollof Rice",
      description:
        "Classic West African rice dish cooked in a flavorful tomato and pepper sauce",
      price: "$6.99",
    },
    {
      name: "White Rice and Stew",
      description: "Steamed white rice served with a rich tomato-based stew",
      price: "$6.99",
    },
    {
      name: "Pounded Yam",
      description:
        "Smooth, stretchy yam mash traditionally paired with Nigerian soups",
      price: "$2.99",
    },
    {
      name: "Amala",
      description: "Nigerian yam flour swallow, perfect for soups",
      price: "$2.99",
    },
    {
      name: "Semolina",
      description:
        "Creamy, dough-like side made from semolina flour, great with soups",
      price: "$2.99",
    },
    {
      name: "Eba",
      description:
        "A dough made from garri (cassava flour), perfect for dipping in soups",
      price: "$2.99",
    },
    {
      name: "Vegetable Medley",
      description: "Mixed sautéed greens lightly seasoned",
      price: "$5.99",
    },
    {
      name: "Steamed Beans and Plantains",
      description: "A mix of steamed black-eyed peas and plantains",
      price: "$7.99",
    },
    {
      name: "Nigerian Salad",
      description:
        "A hearty salad with cabbage, carrots, cucumbers, eggs, and baked beans",
      price: "$6.99",
    },
  ],
  "specialty Dishes": [
    {
      name: "Ukwobi",
      description:
        "Spicy, flavorful dish of seasoned cow foot cooked in rich spices and palm oil, served with onions and Utazi leaves",
      price: "$29.99",
    },
    {
      name: "Isiewu",
      description:
        "Traditional Nigerian spiced goat head dish, cooked in palm oil and seasoned with local spices, garnished with onions",
      price: "$40.99",
    },
    {
      name: "Pepper Pomo",
      description:
        "Soft, spicy braised cow skin cooked in savory Nigerian spices",
      price: "$20.99",
    },
    {
      name: "Pepper Snail",
      description:
        "Tender snails cooked in a spicy pepper sauce, a popular Nigerian delicacy",
      price: "$35.99",
    },
  ],
};

const useMenuData = () => {
  // const [menuData, setMenuData] = useState<MenuData | null>(null);
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const menuCollection = collection(db, "menuData");
  //   const unsubscribe = onSnapshot(menuCollection, (snapshot) => {
  //     const menuList = snapshot.docs.map((doc) => doc.data() as MenuData);
  //     setMenuData(menuList[0]);
  //     setLoading(false);
  //   });

  //   return () => unsubscribe();
  // }, []);

  return { menuData };
};

export default menuData;
export { useMenuData };
